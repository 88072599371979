<template>
  <div class="user">
    <FormView :formData="fromData" :formLabels="formLabels" :column="formColumn" :labelWidth="labelWidth">
      <el-button type="success" @click="addActivity">创建活动</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView :loading="loading" :columns="columns" :data="rTableData" :pagination="pagination"
      :is-pagination-show="isPaginationShow" @getData="getDataList">
      <template #action>
        <el-table-column label="操作" width="280" align="center">
          <template #default="scoped">
            <el-button type="primary" size="small" @click="handleClickLook(scoped.row)">
              详情
            </el-button>
            <!-- <el-button v-if="scoped.row.stageCode != -2" type="primary" size="small"
              @click="handleChangeList(scoped.row.id)">
              查看名单
            </el-button> -->
            <el-button type="primary" size="small" @click="handleClickEdit(scoped.row)">
              修改
            </el-button>
            <el-button v-if="scoped.row.stageCode == -2" type="primary" size="small"
              @click="handleClickDelete(scoped.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog v-model="listVisible" title="用户名单" width="1400px" :before-close="listClose" class="user_dialog">
      <TableView :loading="listLoading" :columns="listColumns" :data="listrTableData" :pagination="listPagination"
        :is-pagination-show="false" @getData="getDataList">
        <template #url>
          <el-table-column label="您的微博或者小红书主页链接" width="240" align="center">
            <template #default="scoped">
              <el-button type="primary" link size="small" :title="scoped.row.questionnaireAnswer4"
                @click="handleClickOpen(scoped.row)">
                {{ scoped.row.questionnaireAnswer4 }}
              </el-button>
            </template>
          </el-table-column>
        </template>
        <template #action>
          <el-table-column label="操作" width="100" align="center">
            <template #default="scoped">
              <el-button type="primary" size="small" @click="handleClickScreen(scoped.row)"
                v-if="scoped.row.activeStatus == 0">
                筛选
              </el-button>
              <el-button type="primary" size="small" @click="handleClickSend(scoped.row)"
                v-if="scoped.row.activeStatus == 2">
                发货
              </el-button>
            </template>
          </el-table-column>
        </template>
      </TableView>
      <div class="block">
        <el-pagination :current-page="listPagination.current" :page-size="listPagination.size"
          layout="total, sizes, prev, pager, next, jumper" :total="listPagination.total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="listClose"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { activityDelete, activityList, userPage, updateStatus } from '@/api/tasteConfiguration'
import { constantAuery } from '@/api/themeConfiguration'
import { useRouter } from 'vue-router'
const router = useRouter()
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入标题或者副标题'
    },
    {
      title: '活动阶段',
      label: 'activityStage',
      type: 'options',
      options: [],
      placeholder: '请选择'
    },
    {
      title: '活动时间',
      label: 'time',
      type: 'datePicker',
      width: '500px'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    activityStage: '',
    time: []
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod (index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'title', label: '主题活动' },
    { prop: 'activityName', label: '子活动标题' },
    { prop: 'subtitle', label: '副标题' },
    { prop: 'beginTime', label: '开始时间' },
    { prop: 'endTime', label: '结束时间' },
    { prop: 'limitedQuotaTotal', label: '总可参与人数' },
    { prop: 'currentAppointmentCount', label: '当前报名人数' },
    { prop: 'stageCodeName', label: '当前活动阶段' },
    { slot: 'action' }
  ],
  listVisible: false,
  listrTableData: [],
  listColumns: [
    {
      index: true,
      indexMethod (index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'phone', label: '手机号码', width: 140 },
    { prop: 'questionnaireAnswer1', label: '前期是否参与过我们的【赏味官】活动？', width: 240 },
    { prop: 'questionnaireAnswer2', label: '您此前参与过哪期【赏味官】活动？', width: 240 },
    { prop: 'questionnaireAnswer3', label: '您是通过什么渠道得知本次活动？', width: 240 },
    { slot: 'url' },
    { prop: 'activeStatusName', label: '活动状态' },
    { slot: 'action' }
  ],
  listLoading: false,
  listPagination: {
    current: 1,
    size: 10,
    total: 0
  },
  userId: ''
})

onMounted(() => {
  getDataList()
  getDict()
})

// 活动阶段
const getDict = () => {
  constantAuery({
    className: 'TasteActivityStage'
  }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      formLabels.value[1].options = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}


// 查看名单
const handleChangeList = id => {
  userId.value = id
  listVisible.value = true
  listLoading.value = true
  const data = {
    current: listPagination.value.current,
    size: listPagination.value.size,
    id: userId.value
  }
  userPage(data).then(({ data: res }) => {
    if (res.code == 200) {
      listLoading.value = false
      listrTableData.value = res.data.records
      listPagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      listrTableData.value = []
      listPagination.value.total = 0
    }
  })
}

// 跳转链接
const handleClickOpen = (row) => {
  window.open(row.questionnaireAnswer4, '_blank')
  // window.open('http://www.baidu.com','_blank')
}

// 查看名单操作
const handleClickScreen = (row) => {
  const data = {
    userSignupId: row.userSignupId,
    activeStatus: 1
  }
  ElMessageBox.confirm('是否确认通过筛选？', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      updateStatus(data).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          handleChangeList(userId.value)
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => { })

}

// 查看名单发货
const handleClickSend = (row) => {
  const data = {
    userSignupId: row.userSignupId,
    activeStatus: 3
  }
  ElMessageBox.confirm('是否确认发货？', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      updateStatus(data).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          handleChangeList(userId.value)
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => { })

}


// 切换页码
const handleCurrentChange = val => {
  listPagination.value.current = val
  handleChangeList(userId.value)
}

// 切换页条
const handleSizeChange = val => {
  listPagination.value.size = val
  handleChangeList(userId.value)
}

const listClose = () => {
  listVisible.value = false
  listrTableData.value = []
  listPagination.value.total = 0
  listPagination.value.current = 1
  listPagination.value.size = 10
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    activityStage: '',
    time: []
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.keyword = fromData.value.keywords
  }
  if (fromData.value.activityStage !== '') {
    data.activityStage = fromData.value.activityStage
  }
  if (fromData.value.time.length > 0) {
    data.beginTime = fromData.value.time[0]
    data.endTime = fromData.value.time[1]
  }
  activityList(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 新增活动弹窗
const addActivity = () => {
  router.push({
    name: `addTasteConfiguration`,
    query: {
      name: '新建'
    }
  })
}

// 编辑
const handleClickEdit = row => {
  router.push({
    name: `addTasteConfiguration`,
    query: {
      id: row.id,
      name: '修改'
    }
  })
}

// 查看详情
const handleClickLook = row => {
  router.push({
    name: `addTasteConfiguration`,
    query: {
      id: row.id,
      name: '详情'
    }
  })
}

// 删除
const handleClickDelete = row => {
  ElMessageBox.confirm('删除后该数据无法恢复，是否确认?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      activityDelete({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => { })
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  listVisible,
  listrTableData,
  listColumns,
  listLoading,
  listPagination,
  userId
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.user_dialog {
  .writeoff_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    span {
      width: 100px;
      text-align: right;
    }

    .dialog_input {
      width: 50%;
    }
  }

  .remark_box {
    align-items: flex-start;
  }

  .block {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
}
</style>
<style lang="scss">
.writeoff_time {
  .el-date-editor {
    width: 50%;
  }
}
</style>
