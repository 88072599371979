import request from '../Axios'

// 新增主题活动
export const activityAdd = data => {
  return request({
    url: '/taste/admin',
    method: 'post',
    data
  })
}

// 修改主题活动
export const activityEdit = data => {
  return request({
    url: '/taste/admin',
    method: 'put',
    data
  })
}

// 删除主题活动
export const activityDelete = params => {
  return request({
    url: `/taste/admin/${params.id}`,
    method: 'delete'
  })
}


// 活动列表
export const activityList = data => {
  return request({
    url: '/taste/admin/query/page',
    method: 'post',
    data
  })
}

// 查询单个主题活动
export const activityEvery = params => {
  return request({
    url: `/taste/admin/${params.id}`,
    method: 'get'
  })
}


// 查看名单
export const userPage = data => {
  return request({
    url: '/template/activityConfig/taste/page/user/signup',
    method: 'get',
    params: data
  })
}


// 筛选
export const updateStatus = data => {
  return request({
    url: '/template/activityConfig/taste/update/status',
    method: 'put',
    data
  })
}
